import React from "react";
import Layout from "../components/layout"

const TerminosCondiciones = () => {
    const portal = <strong>Portal</strong>
    const usuario = <strong>Usuario</strong>
    const empresa = <strong>Empresa</strong>
    return (
        <Layout>
            <main className="row">
                <div className="col-12  m-auto py-5">
                    <h5 className="text-center" style={{letterSpacing:'5px'}}>TERMINOS Y CONDICIONES</h5>
                    <br/>
                    <div style={{textAlign:'justify'}}>
                        <p>Estos Términos y condiciones describen la forma de trabajar de la {empresa} (Construshop) con cada uno
                        de sus clientes, por tal razón, estos Términos y condiciones ayudan a definir la relación que tiene la {empresa} cuando 
                        interactúas  a través de su Sitio Web, no sin antes tomar en consideración lo siguiente: </p>
                        <br/>
                        <h5>1. DEFINICIONES.</h5>
                        <br/>
                        <ul>
                            <li><strong>"El Portal"</strong>: plataforma que será utilizada por la {empresa} para ofrecer productos y servicios al {usuario} y 
                            que podrá ser consultado en la siguiente dirección <a href="http://www.construshop.mx/" target="_blank" rel="noreferrer">www.construshop.mx</a>. </li>
                            <br/>
                            <li><strong>La “Empresa”</strong>: será entendido como la moral <strong>Inmobiliaria y arrendadora visa, S.A. de C.V.</strong> y/o
                            a cada una de las <strong>Empresas</strong> parte de su gripo comercial quienes ofrecen productos y servicios a
                            través de su {portal} Web, el cual se reservan el derecho de modificar discrecionalmente e en
                            cualquier momento, sin necesidad de previo aviso.</li> 
                            <br/>
                            <li><strong>“El Usuario”</strong>: entendido como aquella persona que realiza el uso o accede al {portal}, mediante
                            equipo de cómputo y/o cualquier equipo de comunicación o dispositivo (en adelante el <strong>“Usuario”</strong>),
                            conviene en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en
                            las actividades y/u operaciones del {portal}, en las bases de datos y/o información que se contenga
                            en el mismo.</li> 
                        </ul>

                        <p>A los <strong>Usuarios</strong>, les informamos que los siguientes Términos y Condiciones les son aplicables por el simple
                        uso o acceso a cualquiera de las páginas, aplicaciones web y móviles, softwares y, aplicaciones en general,
                        que integran el {portal}  de <a href="http://www.construshop.mx/" target="_blank" rel="noreferrer">www.construshop.mx</a>, por lo que entenderemos que los acepta y acuerda en
                        obligarse a su cumplimiento. <strong>En el caso de que no esté de acuerdo con los Términos y Condiciones
                        deberá abstenerse de acceder o utilizar el Portal</strong>. </p>
                        <br/>
                        <h5>2. USO Y RESTRICCIONES. </h5>
                        <br/>
                       
                        <p>El acceso o utilización del {portal}  expresan la adhesión plena y sin reservas del {usuario}  a los presentes
                        Términos y Condiciones. A través del {portal} , el {usuario}  se servirá, contratará y/o utilizará diversos
                        servicios y contenidos (los "Servicios y Contenidos"), puestos a disposición por la {empresa}. La {empresa} tendrá 
                        el derecho a negar, restringir o condicionar al {usuario}  el acceso al {portal} , total o parcialmente, a
                        su entera discreción, así como a modificar los Servicios y Contenidos del {portal}  en cualquier momento y 
                        sin necesidad de previo aviso. </p>
                        
                        <p>El {usuario}  reconoce que no todos los Servicios y Contenidos están disponibles en todas las áreas
                        geográficas y que algunos Servicios y Contenidos pueden ser utilizados solamente con posterioridad a su
                        contratación, activación o registro previo por el {usuario}  y/o mediante el pago de estos. La {empresa} no
                        garantiza la disponibilidad y continuidad de la operación del {portal}  y de los Servicios y Contenidos, ni la
                        utilidad del {portal}  o los Servicios y Contenidos en relación con cualquier actividad específica,
                        independientemente del medio de acceso que utilice el {usuario} . La {empresa} no será responsable por
                        daño o pérdida alguna de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o
                        continuidad de operación del {portal}  y/o de los Servicios y Contenidos.</p>

                        <p>El aprovechamiento de los Servicios y Contenidos en el {portal} , es exclusiva responsabilidad del {usuario}, 
                        quien en todo caso deberá servirse de ellos acorde a las funcionalidades permitidas en el propio {portal}  y
                         a los usos autorizados en los presentes Términos y Condiciones, por lo que el {usuario}  se obliga a
                        utilizarlos de tal modo que no contravengan las buenas costumbres, los derechos de terceros, las normas
                        de uso y convivencia en Internet, las leyes de los Estados Unidos Mexicanos y, la legislación vigente en el
                        país en que el {usuario}  se encuentre al acceder al {portal}  y usar los Servicios y Contenidos. El {portal}  es
                        para el uso individual del {usuario}  por lo que no podrá comercializar de manera alguna los Servicios y
                        Contenidos. </p>

                        <p><span className="h5">3. RESTRICCIONES. </span> El {usuario}  no tiene el derecho de colocar híper ligas dentro del Portal , a utilizar las
                        ligas del {portal} , ni el derecho de colocar o utilizar los Servicios y Contenidos en sitios o páginas propias o
                        de terceros sin autorización previa y por escrito de la {empresa}. El {usuario}  no podrá impedir a cualquier
                        otro {usuario}  el uso del {portal}  ni de los Servicios y Contenidos. </p>

                        <p><span className="h5">4. PROPIEDAD INTELECTUAL.</span> Los derechos de propiedad intelectual, propiedad industrial respecto de
                        los Servicios y Contenidos, los signos distintivos y dominios de las Páginas o del {portal} , así como los
                        derechos de uso y explotación de estos, incluyendo de manera enunciativa más no limitativa, su
                        divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de la {empresa}. 
                        El {usuario}  no adquiere ningún derecho de propiedad intelectual y/o industrial por el simple uso 
                        o acceso de los Servicios y Contenidos del {portal}  y, en ningún momento, dicho uso será considerado
                        como una autorización o licencia para utilizar los Servicios y Contenidos con fines distintos a los que se
                        contemplan en los presentes Términos y Condiciones.</p>

                        <p><span className="h5">5. PROPIEDAD INTELECTUAL DE TERCEROS.</span> El {usuario}  acuerda que las disposiciones que se
                        establecen en el artículo 3 anterior respecto de la titularidad de los derechos de la {empresa}, también son
                        aplicables a los derechos de terceros respecto de los Servicios y Contenidos de las Páginas, dominios o
                        información presentada o vinculada al {portal} . </p>

                        <p><span className="h5">6. CALIDAD DE LOS SERVICIOS Y CONTENIDOS Y SUS GARANTÍAS.</span> La {empresa} no será responsable
                        de cualquier daño o perjuicio que sufra el {usuario} a consecuencia de inexactitudes, consultas realizadas,
                        asesorías, errores tipográficos y cambios o mejoras que se realicen periódicamente a los Servicios y
                        Contenidos. Las recomendaciones y consejos obtenidos a través del {portal} son de naturaleza general, por
                        lo que no deben tomarse en cuenta en la adopción de decisiones personales ni profesionales. Para ello, se
                        debe consultar a un profesional apropiado que pueda asesorar al {usuario} de acuerdo con sus necesidades
                        específicas.</p>

                        <p>La {empresa} ofrece los Servicios y Contenidos con un nivel de competencia y diligencia razonable desde
                        un punto de vista comercial, sin embargo, no ofrece ningún tipo de garantía en relación con estos. El {portal} es
                        proporcionado por la {empresa} "tal como está" y "según disponibilidad". La {empresa} no manifiesta
                        ninguna representación o garantía de ningún tipo, expresa o implícita, en relación con la operación
                        del {portal}, información, contenido, materiales o productos incluidos. El {usuario}  acepta expresamente que el
                        uso del {portal}  es bajo su propio riesgo. La {empresa} se reserva el derecho a remover o eliminar cualquier
                        información del {portal}, en cualquier momento, a su entera discreción. Ni la {empresa}, ni sus proveedores
                        o distribuidores ofrecen garantías específicas sobre los Servicios y Contenidos; la {empresa} excluye todas
                        las garantías en la medida que las leyes vigentes lo permitan. </p>
                        <p>De conformidad con los Términos y Condiciones, la {empresa} no asume ni asumirá ningún tipo de
                        responsabilidad frente a ninguna persona, derivada o que pudiera derivarse por los Servicios y Contenidos, 
                        navegación en el {portal}, consultas, aclaraciones y/o cualquier otra clase de respuesta otorgada por parte
                        de la {empresa} por cualquier medio de comunicación. </p>
                        <p>El {usuario} se obliga a dejar en paz y a salvo a la {empresa}, a sus accionistas, subsidiarias, afiliadas,
                        funcionarios, directores, empleados, asesores, apoderados, representantes y/o cualquier persona
                        relacionada con esta, de cualquier responsabilidad que pudiera imputarse en virtud y/o en relación con 
                        el {portal}, la prestación de los Servicios y Contenidos o cualquier otro derivado de los presentes Términos y
                        Condiciones. </p>
                        <p>El {usuario} entiende y acepta que la {empresa} se encontrará limitada por responsabilidad de cualquier tipo,
                        en todos los casos, al monto pagado como contraprestación por los Servicios y Contenidos.</p>

                        <p><span className="h5">6.1 DEVOLUCIONES Y CANCELACIONES.</span> Para seguridad de los <strong>Usuarios</strong>, en cualquier momento
                        podrán cancelar los Servicios y Contenidos que adquieran en el {portal}, a su entera discreción y sin
                        responsabilidad alguna.</p> 
                        <p>El {usuario}  reconoce que los cargos que se realicen a tarjetas de débito o crédito, con motivo de los
                        Servicios y Contenidos, no serán objeto de devolución y, que al realizar dichos pagos se sujeta a los
                        Términos y Condiciones de los proveedores de servicios relacionados con dichos pagos.</p>
                        <p><span className="h5">6.2 ENVÍOS.</span> La {empresa} no será responsable de ninguna demora y/o incumplimiento respecto de los
                        envíos realizados ni mediante terceros ajenos a la {empresa}, el {usuario}  acepta los riesgos adjuntos y se
                        sujeta a los Términos y Condiciones del proveedor de servicios encargado del envío.</p>

                        <p><span className="h5">7. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS.</span> El hecho de que se ofrezca información en 
                        el {portal}  o en otros sitios ligados o vinculados, no implica la recomendación, garantía, patrocinio o aprobación
                        por parte de la {empresa} respecto de dicha información, bienes y/o servicios. La disponibilidad de bienes
                        y/o servicios ofertados por terceros o por sitios ligados o vinculados, no es responsabilidad de la {empresa}.
                        En virtud de lo anterior, la {empresa} no será responsable ante cualquier autoridad de cualquier naturaleza,
                        por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación
                        con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o
                        vinculados a través del {portal}.</p>
                        <p>Respecto de los Servicios y Contenidos que prestan terceros dentro o mediante enlaces a el {portal}  (tales
                        como ligas, banners y botones), la {empresa} se limita exclusivamente, para conveniencia del {usuario} , a:
                        (i) informar al {usuario}  sobre los mismos y, (ii) a proporcionar un medio para poner en contacto al {usuario} con  
                        proveedores o vendedores. Los productos y/o servicios que se comercializan dentro del {portal}  y/o en
                        los sitios de terceros enlazados son suministrados por comerciantes independientes y no se entenderá en
                        ningún caso que son responsabilidad de la {empresa}. No existe ningún tipo de relación laboral, asociación 
                        o sociedad, entre la {empresa} y dichos terceros. Toda asesoría, consejo, declaración, información y
                        contenido de las páginas de terceros enlazadas o dentro del {portal}  representan las opiniones y juicios de
                        dicho tercero, consecuentemente, la {empresa} no será responsable de ningún daño o perjuicio que sufra
                        el {usuario}  a consecuencia de estos. </p>

                        <p><span className="h5">8. CONFIDENCIALIDAD.</span> La {empresa} se obliga a mantener confidencial la información que reciba 
                        del {usuario}  que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos
                        Mexicanos; la {empresa} no asume ninguna obligación de mantener confidencial cualquier otra información
                        que el {usuario}  le proporcione. </p>

                        <p><span className="h5">9. USO DE LA INFORMACIÓN NO CONFIDENCIAL.</span> Mediante el uso del {portal} , el {usuario}  autoriza a la
                        la {empresa}, de manera enunciativa más no limitativa, a utilizar, publicar, reproducir, divulgar, comunicar
                        públicamente y transmitir la información no confidencial, en términos de lo establecido en la Ley Federal 
                        de Protección de Datos Personales en Posesión de Particulares, en la Ley Federal de los Derechos de
                        Autor, en la Ley Federal de Protección al Consumidor y en cualquiera otra aplicable en la legislación
                        mexicana.</p>

                        <p><span className="h5">10. COOKIES.</span> El {usuario}  que tenga acceso al {portal}, conviene en recibir archivos que les transmitan los
                        servidores de la {empresa}. Una "Cookie" es un archivo de datos que se almacena en el disco duro de la
                        computadora del {usuario}  cuando éste acceda al {portal}. Dichos archivos pueden contener información tal
                        como la identificación proporcionada por el {usuario}  o información para rastrear las páginas que el {usuario} ha 
                        visitado. Una Cookie no puede leer los datos o información del disco duro del {usuario}  ni leer las Cookies
                        creadas por otros sitios o páginas.</p>
                        <p>Generalmente, las Cookies son aceptadas automáticamente, el {usuario}  puede cambiar la configuración
                        de su navegador en cualquier momento. En caso de que el {usuario}  decida rechazar las Cookies, es posible
                        que ciertas secciones del {portal}  no tengan su funcionamiento óptimo o incluso no funcionen en absoluto. </p>

                        <p><span className="h5">11. AVISO DE PRIVACIDAD DE DATOS PERSONALES.</span> Toda la información que la {empresa} recabe  
                        del {usuario}  es tratada con absoluta confidencialidad conforme las disposiciones legales aplicables en la
                        legislación mexicana. 
                        Para conocer más sobre la protección de sus datos personales por favor consulte nuestro Aviso de
                        Privacidad. </p>

                        <p><span className="h5">12. CLAVES DE ACCESO.</span> En todo momento, el {usuario}  es el responsable único y final de mantener en
                        secreto las claves de acceso que pudiera tener y con las cuales tuviera acceso a ciertos Servicios y
                        Contenidos del {portal}.</p>
                        <p><span className="h5">12.1 CUENTAS.</span> El {usuario} , al crear una cuenta en el {portal} , declara, bajo protesta de decir verdad, que
                        tiene como mínimo 18 años de edad o la mayoría de edad legal en su jurisdicción; el {usuario}  reconoce
                        que es su responsabilidad cualquier actividad que se desarrolle con dicha cuenta o a través de ella y
                        reconoce que tiene conocimiento y acepta las condiciones establecidas en los presentes Términos y
                        Condiciones y en el Aviso de Privacidad.</p>
                        <p><span className="h5">13. MODIFICACIONES.</span> La {empresa} tendrá el derecho de modificar, en cualquier momento, los Términos
                        y Condiciones, sin previo aviso y/o consentimiento del {usuario} . En consecuencia, el {usuario}  debe leer
                        atentamente los Términos y Condiciones cada vez que pretenda utilizar el {portal} . Ciertos Servicios y 
                        Contenidos ofrecidos a los {usuario} s en y/o a través del {portal}  están sujetos a condiciones particulares
                        propias que sustituyen, completan y/o modifican los presentes Términos y Condiciones.
                        Consiguientemente, el {usuario}  también debe leer atentamente las correspondientes condiciones
                        particulares antes de acceder a cualquiera de los Servicios y Contenidos.</p>
                        <p><span className="h5">14. LEYES APLICABLES Y JURISDICCIÓN.</span> Para la interpretación, cumplimiento y ejecución de los
                        presentes Términos y Condiciones, el {usuario}  está de acuerdo en que serán aplicables las leyes Federales
                        de los Estados Unidos Mexicanos y competentes los tribunales que para tal efecto designe el {portal} ,
                        renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles debido a sus
                        domicilios presentes o futuros o por cualquier otra causa.</p>
                        <p><span className="h5">15. CONTACTO.</span> El {usuario}  puede contactar, en todo momento, al personal de la {empresa} para cualquier
                        aclaración, comentario, duda y/o sugerencia relacionada con los Servicios y Contenidos, con el {portal}  y/o
                        con los presentes Términos y Condiciones en <a href="mailto:inaki.lopez@construshop.mx">inaki.lopez@construshop.mx</a>.</p>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default TerminosCondiciones

export const Head = () => <title>T&eacute;rminos y condiciones</title>